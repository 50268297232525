
.services {
    display: flex;
    gap: 70px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5rem 88px 15px 0px;
    background: #1b1f1e;
  }

  .containerL {
    flex: 2;
  }

  .containerR {
    opacity: 0;
    transition: 1s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 50px;
    flex: 2;
  }

  .containerR.isvisible {
    opacity: 1;
}


  .heading {
    position: relative;
    padding: 0px 0px 20px 88px;
    color: #bac2c2;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .heading::after {
    content: ''; /* Create an empty content */
    position: absolute; /* Position the line absolutely */
    top: 30px;
    left: 88px; /* Align the line to the center */
    width: 30px; /* Adjust the width of the line */
    height: 1px; /* Adjust the height of the line */
    background-color: #9D9898; /* Adjust the color of the line */
    
}

  .image {
    max-width: 100%;
    width: 550px;
    height: 550px;
  }

  .content{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .content1 {
    font-size: 54.4px;
    color: white;
    font-weight: 500;
    line-height: 1.2;
  }
  .content2 {
    color: #b1c2c2;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
  }

  table {
    width: 450px;
    padding-top: 100px;
    padding-bottom: 100px;
    border-collapse: collapse;
  }

  table tr {
    border-top: 2px solid #2d2d2d; /* Add a 1px white border on top of each tr */
  }

  table .ser-name {
    color: #bac2c2;
    font-size: 13px;
    font-weight: 500;
    font-family: "general sans", sans-serif;
    line-height: 2;
    letter-spacing: 0.1rem;
  }

  

  table .ser-list {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .ser-list p{
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  @keyframes slide {
    from{
      transform: translateX(0);
    }
  
    to{
      transform: translateX(-100%);
    }
    
  }
  
  .tools {
    opacity: 40%;
    background: #f2f2f2;
    overflow: hidden;
    padding: 60px 0;
    white-space: nowrap;
    position: relative;
  }
  
  .tools p{
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.8px;
  }
  
  .tools:before,
  .tools:after{
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .tools:before{
    
    left: 0;
    
    
    background: linear-gradient(to left, rgba(255,255,255,0), white);
    
  }
  
  .tools:after{
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255,0), white);
  }
  
  
  .tools-slide {
    display: inline-block;
    animation: 25s slide infinite linear;
  }
  
  .tools-slide img {
    height: 70px;
    margin: 0 40px;
  }

  

 