$primary-font: 'Poppins', sans-serif;
$secondary-font: 'General Sans', sans-serif;
$bg-color: #171E22;
$card-color: #0E1417;
$headingCOlor: white;
$paraColor: #9D9898;


*{
    font-family: $secondary-font;
    margin: 0;
}

*::-webkit-scrollbar{
    display: none;
}


  