@import '../../index.scss';



.home{
  padding: 40px 88px;
  display: flex;
  flex-direction: column;
  gap: 70px;
}




.home1{
  display: flex;
  flex-direction: row-reverse;
  gap: 70px;
}

.home-desc{
  color: #273332;
  font-size: 130px;
  font-weight: 500;
  line-height: 100px;
  text-transform: uppercase;
}

.home2{
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home2 p{
  color: #273332;
  font-weight: 500;
  font-size: 18px;
}

.home2 img{
  width: 40px;
  height: 40px;
  rotate: 135deg;
}

.home .homePic{
  height: 400px;
  object-fit: cover;
}

.accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-item .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #f9f9f9;
}

.accordion-item .accordion-header .icon {
  font-size: 20px;
}

.accordion-item .accordion-content {
  display: none;
  padding: 10px;
  background-color: #fff;
}

.accordion-item.active .accordion-content {
  display: block;
}

/*small screens*/
@media only screen and (min-width: 100px) and (max-width: 300px) {
  .home{
    padding: 3rem 13px 2rem 13px;
    gap: 20px;
  }

  .home1{
    flex-direction: column-reverse;
    gap: 10px;
  }

  .home-desc{
    font-size: 20.4px;
    line-height: 1.2;
  }

  .home2{
    width: auto;
    gap: 10px;
  }
  
  .home2 p{
    font-size: 10px;
  }
  
  .home2 img{
    width: 16px;
    height: 16px;
    rotate: 45deg;
  }

  .home .homePic{
    height: auto;
    object-fit: cover;
  }
}

/*mobile screens small-medium*/
@media only screen and (min-width: 300px) and (max-width: 400px) {

  .home{
    padding: 1rem 24px;
    gap: 30px;
  }

  .home1{
    flex-direction: column-reverse;
    gap: 20px;
  }

  .home-desc{
    font-size: 41px;
    line-height: 1;
  }

  .home2{
    width: auto;
    gap: 10px;
  }
  
  .home2 p{
    font-size: 15px;
  }
  
  .home2 img{
    width: 20px;
    height: 20px;
    rotate: 45deg;
  }

  .home .homePic{
    height: auto;
    object-fit: cover;
  }
}

@media only screen and (min-width: 400px) and (max-width: 800px) {

  .home{
    padding: 3rem 24px;
    gap: 30px;
  }

  .home1{
    flex-direction: column-reverse;
    gap: 20px;
  }

  .home-desc{
    font-size: 43.4px;
    line-height: 1;
  }

  .home2{
    width: auto;
    gap: 10px;
  }
  
  .home2 p{
    font-size: 18px;
  }
  
  .home2 img{
    width: 25px;
    height: 25px;
    rotate: 45deg;
  }

  .home .homePic{
    height: auto;
    object-fit: cover;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1190px) {

  .home{
    padding: 3rem 48px;
    gap: 30px;
  }

  .home1{
    gap: 50px;
  }

  .home-desc{
    font-size: 73.4px;
    line-height: 0.9;
  }

  .home2{
    width: 100px;
    gap: 10px;
  }
  
  .home2 p{
    font-size: 12px;
  }
  
  .home2 img{
    width: 25px;
    height: 25px;
    rotate: 45deg;
  }

  .home .homePic{
    height: auto;
    object-fit: cover;
  }
}