// .navbar {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background-color: #273332;
//   color: white;
//   padding: 10px 20px;
// }

// .logo {
//   font-size: 24px;
// }

// .menu-icon {
//   font-size: 24px;
//   cursor: pointer;
// }

// .overlay {
//   position: fixed;
//   z-index: 999;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   width: 300px; 
//   background-color: #273332;
//   color: white;
//   padding: 20px;
//   transition: transform 0.3s ease-in-out;
//   transform: translateX(0);
// }

// .overlay.active {
//   transform: translateX(0%);
//   right: 100%;
// }

// .close-icon {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
// }

// .nav-links {
//   list-style-type: none;
//   padding: 0;
// }

// .nav-links li {
//   margin-bottom: 10px;
// }

// .nav-links a {
//   text-decoration: none;
//   color: white;
//   font-size: 18px;
// }





// .radio-inputs {
//   position: fixed;
//   bottom: 30px;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 100;
//   display: flex;
//   flex-wrap: wrap;
//   border-radius: 50px;;
//   background-color: #273332;
//   width: auto;
//   gap: 25px;
//   font-size: 20px;
//   font-weight: 500;
//   padding: 10px;
// }

// .radio-inputs .radio {
//   flex: 1 1 auto;
//   text-align: center;
// }

// .radio-inputs .radio input {
//   display: none;
// }

// .radio-inputs .radio .name {
//   display: flex;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50px;
//   border: none;
//   padding: .5rem 15px;
//   color: white;
//   transition: all .15s ease-in-out;
// }

// .name a{
//   text-decoration: none;
//   color: white;
// }

// .radio-inputs .radio input:checked + .name {
//   background-color: #fff;
//   font-weight: 600;
// }











// .radio-inputs {
//   position: fixed;
//   bottom: 30px;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 100;
//   display: flex;
//   flex-wrap: wrap;
//   border-radius: 50px;
//   background-color: #273332;
//   width: auto;
//   gap: 25px;
//   font-size: 20px;
//   font-weight: 500;
//   padding: 10px;
// }

// .radio-inputs .radio {
//   flex: 1 1 auto;
//   text-align: center;
// }

// .radio-inputs .radio input {
//   display: none;
// }


// a {
//   text-decoration: none;
//   color: white;
// }

// .radio-inputs .radio .name {
//   display: flex;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50px;
//   border: none;
//   padding: .5rem 15px;
//   color: white;
//   transition: all .15s ease-in-out;
// }

// .radio-inputs .radio input:checked + a{
//   background-color: #fff !important; 
//   font-weight: 600;
  
// }

.Nav{
  display: flex;
  justify-content: space-between;
  padding: 10px 88px;
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
  border-bottom: .1px solid #273332;
}



.home-heading {
  font-size: 40px; /* Adjust font size as needed */
  /* Set the text color to transparent */
  -webkit-text-stroke: 1.2px black; 
  text-align: center;
color: transparent;
}





.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #EEE;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 300px;
  font-size: 14px;
}



a:link{
text-decoration: none;
color: rgba(51, 65, 85, 1);
}

a:visited{

color: rgba(51, 65, 85, 1);;;

}


.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: .5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all .15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: #fff;
  font-weight: 600;
}