@import '../../index.scss';

.footer {
  display: flex;
  flex-direction: column;
  padding: 70px 88px 20px 88px;
  gap: 60px;
}

.footer1 {
  display: flex;
  gap: 70px;

}

.footer1-1 {
  width: 100px;
}

.footer1-1 p {
  color: #88a09d;
  font-size: 20px;
  font-weight: 500;
  width: 100px;
}

.footer1-2 {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.footer-inv {
  font-weight: 600;
  color: #273332;
  font-size: 80px;
  line-height: 85px;
  text-transform: uppercase;
}


.footer-buttons {
  display: flex;
  gap: 10px;
}


button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.join-us {
  width: 12rem;
  height: auto;
}

button.join-us .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #1b1f1e;
  border-radius: 1.625rem;
}

button.join-us .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #bac2c2;
}

button.join-us .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.join-us .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #bac2c2;
  border-right: 0.125rem solid #bac2c2;
  transform: rotate(45deg);
}

button.join-us .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #bac2c2;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #bac2c2;
}


.footer-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-nav a {
  color: #273332;
  font-size: 23px;
  font-weight: 500;
}


.footer-nav a,
.footer-nav a:after,
.footer-nav a:before {
  transition: all .5s;
}

.footer-nav a {
  position: relative;
}

.footer-nav a:hover:after {
  color: #000000;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 10%;
  content: '.';
  // color: transparent;
  // background: #707070;
  height: 1px;
}

// .footer-nav a:hover:after {
//   width: 100%;
// }

.footer2 {
  display: flex;
  justify-content: space-between;
}

.footer-logo img {
  width: 150px;
}

.footer-social {
  display: flex;
  gap: 35px;
}

.footer-social img {
  width: 25px;
  height: 25px;
}



@media only screen and (min-width: 100px) and (max-width: 299px) {

  .footer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .footer1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .footer1-1 {
    width: auto;
  }

  .footer1-1 p {
    width: 100%;
    font-size: 12px;
  }

  .footer-inv {

    font-size: 30.4px;
    line-height: 1.2;
  }

  .footer1-2 {
    gap: 35px;
  }


  .footer2 {
    flex-direction: column;
    gap: 20px;
  }

  .footer-logo {
    display: flex;
    justify-content: flex-start;
  }

  .footer-logo img {
    width: 100px;
  }

  .footer-nav a {
    font-size: 12px;
  }

  .footer-social {
    gap: 15px;
  }

  .footer-social img {
    width: 15px;
    height: 15px;
  }

  .footer-desc {
    font-size: 10px;
  }

  .footer-desc .footer-inv {
    font-size: 27px;
    line-height: 25px;
    font-weight: 600;
  }

  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  button.join-us {
    width: 10rem;
    /* Adjust the width for mobile */
    height: auto;
    /* Allow height to adjust based on content */
  }

  button.join-us .circle {
    width: 2.5rem;
    /* Adjust circle size for mobile */
    height: 2.5rem;
    /* Adjust circle size for mobile */
  }

  button.join-us .circle .icon.arrow {
    // left: 0.5rem; /* Adjust arrow position for mobile */
    width: 0.75rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .circle .icon.arrow::before {
    top: -0.2rem;
    /* Adjust arrow size for mobile */
    right: 0.05rem;
    /* Adjust arrow size for mobile */
    width: 0.375rem;
    /* Adjust arrow size for mobile */
    height: 0.375rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .button-text {
    margin: 0 0 0 1rem;
    font-size: 0.65rem;
  }

  button:hover .circle {
    width: 100%;
  }


}


@media only screen and (min-width: 300px) and (max-width: 400px) {


  .footer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .footer1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .footer1-1 {
    width: auto;
  }

  .footer1-1 p {
    width: 100%;
    font-size: 12px;
  }

  .footer-inv {

    font-size: 30.4px;
    line-height: 1.2;
  }

  .footer1-2 {
    gap: 35px;
  }


  .footer2 {
    flex-direction: column;
    gap: 20px;
  }

  .footer-logo {
    display: flex;
    justify-content: flex-start;
  }

  .footer-logo img {
    width: 100px;
  }

  .footer-nav a {
    font-size: 12px;
  }

  .footer-social {
    gap: 15px;
  }

  .footer-social img {
    width: 15px;
    height: 15px;
  }

  .footer-desc {
    font-size: 10px;
  }

  .footer-desc .footer-inv {
    font-size: 27px;
    line-height: 25px;
    font-weight: 600;
  }

  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  button.join-us {
    width: 10rem;
    /* Adjust the width for mobile */
    height: auto;
    /* Allow height to adjust based on content */
  }

  button.join-us .circle {
    width: 2.5rem;
    /* Adjust circle size for mobile */
    height: 2.5rem;
    /* Adjust circle size for mobile */
  }

  button.join-us .circle .icon.arrow {
    // left: 0.5rem; /* Adjust arrow position for mobile */
    width: 0.75rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .circle .icon.arrow::before {
    top: -0.2rem;
    /* Adjust arrow size for mobile */
    right: 0.05rem;
    /* Adjust arrow size for mobile */
    width: 0.375rem;
    /* Adjust arrow size for mobile */
    height: 0.375rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .button-text {
    margin: 0 0 0 1rem;
    font-size: 0.65rem;
  }

  button:hover .circle {
    width: 100%;
  }


}


@media only screen and (min-width: 401px) and (max-width: 800px) {


  .footer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .footer1 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .footer1-1 {
    width: auto;
  }

  .footer1-1 p {
    width: 100%;
    font-size: 14px;


  }

  .footer-inv {
    font-weight: 600;
    color: #273332;
    font-size: 33.4px;
    line-height: 1.2;
  }

  .footer1-2 {
    gap: 35px;
  }


  .footer2 {
    flex-direction: column;
    gap: 20px;
  }

  .footer-logo {
    display: flex;
    justify-content: flex-start;
  }

  .footer-logo img {
    width: 100px;
  }

  .footer-nav a {
    font-size: 12px;
  }

  .footer-social {
    gap: 15px;
  }

  .footer-social img {
    width: 15px;
    height: 15px;
  }

  .footer-desc {
    font-size: 10px;
  }

  .footer-desc .footer-inv {
    font-size: 27px;
    line-height: 25px;
    font-weight: 600;
  }

  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }


  button.join-us .circle {
    width: 2.7rem;
    /* Adjust circle size for mobile */
    height: 2.7rem;
    /* Adjust circle size for mobile */
  }

  button.join-us .circle .icon.arrow {
    left: 0.85rem;
    /* Adjust arrow position for mobile */
    width: 0.75rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .circle .icon.arrow::before {
    top: -0.2rem;
    /* Adjust arrow size for mobile */
    right: 0.05rem;
    /* Adjust arrow size for mobile */
    width: 0.375rem;
    /* Adjust arrow size for mobile */
    height: 0.375rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .button-text {
    margin: 0 0 0 1rem;
    font-size: 0.85rem;
  }

  button:hover .circle {
    width: 100%;
  }

}



@media only screen and (min-width: 801px) and (max-width: 1190px) {


  .footer {
    padding: 3rem 48px 30px 48px;
    gap: 30px;
  }

  .footer1 {
    flex-direction: column;
    gap: 0;
  }

  .footer1-1 {
    width: auto;
  }

  .footer1-1 p {
    font-size: 16px;
    width: auto;
  }

  .footer1-2{
    gap: 30px;
  }

  .footer-inv {
    font-size: 38.4px;
    line-height: 1.3;
  }

  .footer-nav a{
    font-size: 14px;
  }

  .footer-social img{
    width: 20px;
    height: 20px;
  }



  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  button.join-us .circle {
    width: 2.7rem;
    /* Adjust circle size for mobile */
    height: 2.7rem;
    /* Adjust circle size for mobile */
  }

  button.join-us .circle .icon.arrow {
    left: 0.85rem;
    /* Adjust arrow position for mobile */
    width: 0.75rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .circle .icon.arrow::before {
    top: -0.2rem;
    /* Adjust arrow size for mobile */
    right: 0.05rem;
    /* Adjust arrow size for mobile */
    width: 0.375rem;
    /* Adjust arrow size for mobile */
    height: 0.375rem;
    /* Adjust arrow size for mobile */
  }

  button.join-us .button-text {
    margin: 0 0 0 1rem;
    font-size: 0.85rem;
  }

  button:hover .circle {
    width: 100%;
  }

}