@import '../../index.scss';


/*General styles */
.diagonal-sections{
    z-index: 1;
    height: 20rem;
    transform-origin: 50%;
    position: relative;
    overflow: hidden;
}

.diagonal1{
    z-index: 10;
    width: 200%;
    height: 20rem;
    background-color: #e1e7e7;
    transform-origin: 50%;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: -50%;
    right: 0%;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateZ(6deg) ;
}

.diagonal2{
    z-index: 20;
    width: 200%;
    height: 20rem;
    background-color:#1b1f1e ;
    transform-origin: 50%;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: -50%;
    right: 0%;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateZ(-6deg) ;
}



.about{
    background:#1b1f1e ;
    
}


.about-container{
    
    opacity: 0;
    transition: 1s ease-in-out;
    margin: 0;
    padding: 5rem 88px;
    display: flex;
    flex-direction: row;
    gap: 70px;
}

.about-container.visible {
    opacity: 1;
}

.topic {
    color: #bac2c2;
    font-size: 20px;
    font-weight: 500;
    position: relative; 
    width: 100px;
}

.topic::after {
    content: ''; /* Create an empty content */
    position: absolute; /* Position the line absolutely */
    bottom: 0px; /* Adjust the position of the line */
    left: 0; /* Align the line to the center */
    width: 30px; /* Adjust the width of the line */
    height: 1px; /* Adjust the height of the line */
    background-color: #9D9898; /* Adjust the color of the line */
    
}

.aboutcontent1{
    color: white;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;

}

.aboutcontent2{
    color: #bac2c2;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;

}

/*small mobile screens*/
@media only screen and (min-width: 100px) and (max-width: 300px) {
    
    .about{
        padding: 3rem 13px;
    }

    .diagonal-sections{
        height: 6rem;
    }
   
    
    .about-container{
        display: flex;
        flex-direction: column;
        padding: 0 0px;
        gap: 20px;
    }


    .topic{
        font-size: 11px;
    }

    .topic::after{
        width: 20px;
    }

    .aboutcontent1{
        font-size: 20.4px;
        line-height: 1.2;
    }

    .aboutcontent2{
        font-size: 9px;
        line-height: 1.5;
    }

}



/* medium sized mobiles*/
@media only screen and (min-width: 300px) and (max-width: 400px) {
    
    .about{
        padding: 3rem 24px;
    }

    .diagonal-sections{
        height: 10rem;
    }
   
    
    .about-container{
        display: flex;
        flex-direction: column;
        padding: 0 0px;
        gap: 20px;
    }


    .topic{
        font-size: 13px;
    }

    .topic::after{
        width: 20px;
    }

    .aboutcontent1{
        font-size: 30.4px;
        line-height: 1.2;
    }

    .aboutcontent2{
        font-size: 18px;
        line-height: 1.5;
    }

}

/* medium sized mobiles tested with iphones series*/
@media only screen and (min-width: 401px) and (max-width: 800px) {
    
    .about{
        padding: 5rem 0px;
    }

    .diagonal-sections{
        height: 10rem;
    }
   
    
    .about-container{
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        gap: 20px;
    }


    .topic{
        font-size: 18px;
    }

    .aboutcontent1{
        font-size: 33.4px;
        line-height: 1.2;
    }

    .aboutcontent2{
        font-size: 18px;
        line-height: 1.6;
    }

}

/*tablet screens all the way to website screens and small screen laptops*/
@media only screen and (min-width: 801px) and (max-width: 1190px) {
    

    .about{
        padding: 7rem 0px;
    }

    .diagonal-sections{
        height: 10rem;
    }
   
    
    .about-container{
        display: flex;
        flex-direction: column;
        padding: 0 48px;
        gap: 20px;
    }


    .topic{
        font-size: 19.2px;
    }

    .aboutcontent1{
        font-size: 38.4px;
        line-height: 1.2;
    }

    .aboutcontent2{
        font-size: 18px;
        line-height: 1.6;
    }

}


