.Career {
    padding: 5rem 88px ;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.Career h2 {
    font-size: 20px;
    color: #273332;
    font-weight: 500;
    position: relative;
}

.Career h2::after {
    content: ''; /* Create an empty content */
    position: absolute; /* Position the line absolutely */
    bottom: 0px; /* Adjust the position of the line */
    left: 0; /* Align the line to the center */
    width: 30px; /* Adjust the width of the line */
    height: 1px; /* Adjust the height of the line */
    background-color: #9D9898; /* Adjust the color of the line */
    
}



summary {
    list-style: none;
}

summary::-webkit-details-marker {
    display: none;
}

summary {
    display: flex;
    justify-content: space-between;
    border-bottom: .5px solid #273332;
    padding: .75em 1em;
    cursor: pointer;
    margin-bottom: 10px;
}



.job_name {
    color: #273332;
    font-size: 54.4px;
    font-weight: 500;
}


.job_locationarrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.location {
    color: #273332;
    font-size: 17.6px;
    font-weight: 500;
}

.job_locationarrow img {
    width: 20px;
    height: 20px;
    rotate: 90deg;
}


.accordion-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 2rem;
    border: .5px solid #273332;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* Apply transition effect */
}


.job-cards {
    display: flex;
    flex-direction: row;
    gap: 10px;
}








.location-box {
    padding: 15px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: #273332;
    max-width: fit-content;
    border-radius: 10px
}



.location-ball {
    display: flex;
    justify-content: space-between;
}


.location-ball .location_heading {
    color: #b1c2c2;
    font-size: 10px;
    font-weight: 500;
}

.location-ball .ball {
    width: 10px;
    height: 10px;
    background-color: white;
    ;
    border-radius: 60px;
}

.job-location {
    color: white;
    font-size: 20px;
    font-weight: 500;
}






.job-roles {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.job-roles h2 {
    font-size: 14px;
    font-weight: 500;
    color: #273332;
}

.roles {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.role {
    padding: 3px 38px;
    border-radius: 60px;
    border: 1px solid black;
    height: auto;
    text-align: center;
}

.roles span {
    font-size: 19px;
    color: #273332;
    font-weight: 500;
}


.responsibility ul {
    padding: 0;
}

.responsibility li {
    list-style: none;
    color: #273332;
    font-size: 25px;
    font-weight: 500;
}